<template>
  <div class="auth-wrapper auth-v2">
    <b-overlay :show="loading">
      <b-row class="auth-inner m-0">
        <!-- Brand logo-->
        <b-link class="brand-logo" style="left: 5%;">
          <h1 class="brand-text text-primary">{{ appShortName }}</h1>
        </b-link>
        <!-- /Brand logo-->

        <!-- Reset password-->
        <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
            <h2 title-tag="h2" class="font-weight-bold mb-1">
              Ganti Kata Sandi 🔒
            </h2>
            <b-card-text class="mb-2">Buat Kata Sandi Baru Kamu</b-card-text>

            <!-- form -->
            <validation-observer ref="resetPassword" #default="{ invalid }">
              <b-form
                class="auth-reset-password-form mt-2"
                method="POST"
                @submit.prevent="submit"
              >
                <!-- password -->
                <b-form-group
                  label="Kata Sandi Baru"
                  label-for="reset-password-new"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Kata Sandi"
                    vid="Kata Sandi"
                    rules="required|min:8"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="reset-password-new"
                        v-model="password"
                        :type="password1FieldType"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        name="reset-password-new"
                        placeholder="Masukkan Kata Sandi"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password1ToggleIcon"
                          @click="togglePassword1Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- confirm password -->
                <b-form-group
                  label-for="reset-password-confirm"
                  label="Konfirmasi Kata Sandi"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Konfirmasi Kata Sandi"
                    rules="required|confirmed:Kata Sandi"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="reset-password-confirm"
                        v-model="cPassword"
                        :type="password2FieldType"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false : null"
                        name="reset-password-confirm"
                        placeholder="Masukkan Kata Sandi"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password2ToggleIcon"
                          @click="togglePassword2Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit button -->
                <b-button
                  block
                  type="submit"
                  :disabled="invalid"
                  variant="primary"
                >
                  Simpan
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <b-link :to="{ name: 'auth-login' }">
                <feather-icon icon="ChevronLeftIcon" />
                Kembali Login
              </b-link>
            </p>
          </b-col>
        </b-col>
        <!-- /Reset password-->

        <!-- Left Text-->
        <b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
          <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
          >
            <b-img :src="imgUrl" fluid alt="Register V2" />
          </div>
        </b-col>
        <!-- /Left Text-->
        <p
          class="clearfix mb-0"
          style="position: absolute; top: 93%; left: 5%;"
        >
          <span class="float-md-left d-block d-md-inline-block mt-25">
            © {{ new Date().getFullYear() }} {{ appName }}
            <span class="d-none d-sm-inline-block">. All rights Reserved</span>
          </span>
        </p>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BOverlay,
  BRow,
  BCol,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BOverlay,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      token: this.$route.params.token,
      loading: false,
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === 'password' ? 'text' : 'password'
    },
    submit() {
      this.$refs.resetPassword.validate().then((success) => {
        if (success) {
          this.loading = true
          const payload = {
            token: this.token,
            password: this.password,
          }
          this.$store
            .dispatch('auth/resetPassword', payload)
            .then((res) => {
              this.loading = false
              this.$router.replace('/login').then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Berhasil`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: res.data.message,
                  },
                })
              })
            })
            .catch((error) => {
              this.loading = false
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error `,
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
